<ng-container *ngIf="dataCustomerRow$ | async as customerDocument">
  <ng-container>
    <div class="list-customer-document" *ngIf="customerDocument.length > 0" style="margin-left: 70px;max-height: 1000px;overflow-y: auto;">
      <div class="stat-row flex align-items-start" *ngFor="let document of customerDocument">
        <div class="label flex-1" style="max-width: 40rem;">
          <div class="font-bold w-full h-2rem m-1 relative">
            <div class="z-1 absolute p-2 left-0 top-0 h-full"
            >
              {{document.customerSummaries?.groupCustomerName}}
            </div>
          </div>
        </div>
        <div class="documents flex-0 w-12rem mx-3">
          <p-button        link="true"
                           *ngIf="document?.loading === false; else loadingStats"
                           [label]="(document?.customerSummaries?.groupTotalCount  || 0) + ' documents'"
                           [disabled]="document?.customerSummaries?.groupTotalCount === 0"
                           (onClick)="getCustomerDocumentsRouterLink(document?.customerSummaries?.groupId!)"
          ></p-button>
        </div>
      </div>
    </div>
    <ng-template #loadingStats>
      <div class="inline-block">
        <button type="button" pButton
                class="block w-full p-button-text text-right"
                [disabled]="true"
                icon="pi pi-spinner"
                label="Chargement..."
        ></button>
      </div>
    </ng-template>

    <div class="d-flex justify-content-center pl-8 font-bold" *ngIf="customerDocument.length == 0">
      Il n'y a aucun dossier trouvé.
    </div>
  </ng-container>
</ng-container>
