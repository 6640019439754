import {Injectable} from "@angular/core";
import {BehaviorSubject, defaultIfEmpty, EMPTY, forkJoin, Observable, of, shareReplay, switchMap, take, tap} from "rxjs";
import {catchError, debounceTime, filter, map} from "rxjs/operators";
import {CustomerDocumentService, ErrorService, FieldIdentificationValueService, IndexingFieldHandlersService} from "@fiscalteam/ngx-nitro-services";
import {WsFieldIdentificationValue, WsFieldIdentificationValueStatus} from "@fiscalteam/nitro-domain-client";
import {IndexerDocumentFormModel} from "./indexer-document-form-model";
import {IndexerDocumentFormFieldModel} from "./indexer-document-form-field-model";

@Injectable()
export class IndexerDocumentFormSubmitService {

  /**
   * An observable emitting true when the form is submitting.
   */
  submitting$: Observable<boolean>;

  private submittingModels$ = new BehaviorSubject<IndexerDocumentFormModel[]>([]);

  constructor(
    private fieldHandlerService: IndexingFieldHandlersService,
    private fieldValueService: FieldIdentificationValueService,
    private errorService: ErrorService,
    private customerDocumentService: CustomerDocumentService,
  ) {
    this.submitting$ = this.submittingModels$.pipe(
      map(l => l.length > 0),
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  /**
   * Submit the current form model. Return the persisted form model, with values updated
   */
  submitForm$(formModel: IndexerDocumentFormModel): Observable<(WsFieldIdentificationValue | undefined)[]> {
    const submittingModels = this.submittingModels$.getValue();
    const submittingCurrentModel = submittingModels.indexOf(formModel) >= 0;
    if (submittingCurrentModel) {
      console.log(`Silencing submit because already submitting`);
      return EMPTY;
    }

    // We should prevent attempting to submit the form too soon (for instance using keyboard shortcuts).
    // All required fields must have an indexer value
    const fieldModelMissingIndexerValue = formModel.fieldModels.find(f => {
      // Whether we are still required to submit a value
      const missingIndexerValue = f.fieldModel.isIndexerValueMissingNow();
      // Whether we are about to submit something already
      const fieldReadyToSubmit = f.pendingSubmitLocalChanges && f.pendingSubmitLocalChanges.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
      return missingIndexerValue && !fieldReadyToSubmit;
    });
    if (fieldModelMissingIndexerValue != null) {
      console.log([`Silencing submit because a required request has no value`, fieldModelMissingIndexerValue]);
      return EMPTY;
    }

    this.submittingModels$.next([...submittingModels, formModel]);
    const submittedFields$List = formModel.fieldModels.map(field => this.submitField$(field));
    const submitedvaluesList$ = submittedFields$List.length === 0 ? of([]) : forkJoin(submittedFields$List);
    return submitedvaluesList$.pipe(
      tap({
        next: a => this.onModelSubmitted(formModel),
        error: e => this.onModelSubmitError(formModel, e),
      }),
    );
  }


  private submitField$(fieldModel: IndexerDocumentFormFieldModel): Observable<WsFieldIdentificationValue | undefined> {
    const indexerValue = fieldModel.fieldModel.indexerValue$.getValue();
    if (indexerValue == null) {
      console.warn([`Skipping submitting field because no indexer value`, fieldModel]);
      return of(undefined);
    }

    const hasFinalValue = indexerValue.valueStatus === WsFieldIdentificationValueStatus.Problem
      || indexerValue.valueStatus === WsFieldIdentificationValueStatus.Submitted;
    if (hasFinalValue) {
      console.warn([`Skipping submitting field because indexer value final`, fieldModel]);
      return of(indexerValue);
    }

    // if syncing value, wait until done and retry
    return fieldModel.fieldModel.indexerValueSyncing$.pipe(
      debounceTime(500), // debounce more than during sync so we dont miss any event
      filter(s => !s), // Wait until synched
      take(1),
      tap(a => {
        // Make sure to unsubscribe
        if (fieldModel.indexerValuesSyncSubscription) {
          fieldModel.indexerValuesSyncSubscription.unsubscribe();
        }
      }),
      switchMap(m => this.submitSyncField$(fieldModel)),
    );
  }

  private submitSyncField$(fieldModel: IndexerDocumentFormFieldModel): Observable<WsFieldIdentificationValue | undefined> {
    const curBackendFieldValue = fieldModel.fieldModel.indexerValue$.getValue(); // Might still not be persisted
    const lastFieldValueUpdate = fieldModel.fieldModel.fieldValueUpdateSource$.getValue();
    const fieldValueToSaveFirst: WsFieldIdentificationValue = Object.assign({}, curBackendFieldValue, lastFieldValueUpdate) as WsFieldIdentificationValue;

    // Make sure we unsubscribe for all synchronization that may still be happening
    if (fieldModel.indexerValuesSyncSubscription) {
      fieldModel.indexerValuesSyncSubscription.unsubscribe();
    }

    // Make sure UI does not alter our future changes while submitting
    const localChangesToSubmit = Object.assign({}, fieldModel.pendingSubmitLocalChanges);

    // 1: submit current field value so that we are certain it is persisted on the backend
    // 2: submit current typed value sot that we are certain it is persisted on the backend
    // 3. apply local changes (status update), and update the backend value again

    return this.fieldValueService.saveFieldIdentificationValueHandlingErrors$(fieldValueToSaveFirst, true, true).pipe(
      switchMap(createdValue => this.submitTypedValuePriorSubmit$(createdValue, fieldModel)),
      switchMap(updatedValue => this.submitPendingLocalChanges$(updatedValue, localChangesToSubmit, fieldModel)),
      catchError(e => {
        this.errorService.handleError(e);
        console.warn(`Unable to save field value for ${fieldModel.fieldModel.field.code}: ${e}`);
        return of(undefined);
      })
    );
  }

  private submitTypedValuePriorSubmit$(createdValue: WsFieldIdentificationValue, fieldModel: IndexerDocumentFormFieldModel) {
    // getIndexerTypedValue$ will return  the indexer value when present
    return fieldModel.fieldModel.getIndexerTypedValue$().pipe(
      switchMap(v => this.fieldHandlerService.setFieldValueHandlingErrors$({id: createdValue.id!}, fieldModel.fieldModel.field, v, true)),
      map(update => Object.assign({}, createdValue, update)),
    );
  }

  private submitPendingLocalChanges$(updatedValue: WsFieldIdentificationValue, localChangesToSubmit: Partial<WsFieldIdentificationValue> | undefined, fieldModel: IndexerDocumentFormFieldModel) {
    const valueToSubmit = Object.assign({}, updatedValue, localChangesToSubmit);
    // Ensure we are submitting the field with a final status. If it not specified, assume it is SUBMITTED.
    // This may happen when reloading a DISPLAYED value from the backend without asking the indexer to fill the field again
    if (valueToSubmit.valueStatus === WsFieldIdentificationValueStatus.Displayed) {
      valueToSubmit.valueStatus = WsFieldIdentificationValueStatus.Submitted;
    }
    return this.fieldValueService.saveFieldIdentificationValueHandlingErrors$(valueToSubmit, true, true);
  }

  private onModelSubmitted(formModel: IndexerDocumentFormModel) {
    // Clear doc cache to fetch new status
    this.customerDocumentService.clearDocumentFromCache({id: formModel.document.id!});
    this.removeModelFromSubmittingList(formModel);
  }

  private onModelSubmitError(formModel: IndexerDocumentFormModel, error: any) {
    console.warn([`Document submit error`, error]);
    // Clear doc cache to fetch new status
    this.customerDocumentService.clearDocumentFromCache({id: formModel.document.id!});
    this.removeModelFromSubmittingList(formModel);
  }

  private removeModelFromSubmittingList(formModel: IndexerDocumentFormModel) {
    const curModels = this.submittingModels$.getValue();
    const newModels = curModels.filter(m => m !== formModel);
    this.submittingModels$.next(newModels);
  }

}
