<div class="document-list h-full w-full overflow-hidden">

  <p-table
    [dataKey]="'customerDocument.id'"
    [lazy]="true"
    (onLazyLoad)="loadPage($event)"
    [lazyLoadOnInit]="false"
    [rows]="(tableHelper.getPagination$() | async)?.length || 0"
    [totalRecords]="(tableHelper.getTotalRowCount$() | async) || 0"
    [first]="(tableHelper.getPagination$() | async)?.first || 0"
    [multiSortMeta]="(tableHelper.getPaginationSortMetas$() | async) || []"
    [paginator]="true"
    [rowHover]="true"
    [scrollable]="true"
    [selectionMode]="null"
    [value]="(tableHelper.getPageRowModels$() | async) || []"
    [resizableColumns]="true"
    [columns]="(tableHelper.getWeightedColumns$() | async) || []"
    [filters]="(tableHelper.getColumnFiltersMetadata$() | async) || {}"
    [reorderableColumns]="true"
    (onColReorder)="tableHelper.setWeightedColumns($event.columns || [])"
    columnResizeMode="expand"
    styleClass="h-full"
    #tableComponent
  >
    <ng-template pTemplate="header" let-columns>
      <tr class="overflow-hidden">
        <th>Id</th>
        <th>Date</th>
        <th>Dossier</th>
        <th>Nom</th>
        <th>Status</th>
      </tr>
    </ng-template>

    <ng-template let-row let-columns="columns" pTemplate="body">
      <tr
        (click)="onRowClick(row)"
        [class.cursor-pointer]="true"
        class="overflow-hidden"
      >
        <td>
          {{ row.customerDocument.id }}
        </td>
        <td>
          {{ row.customerDocument.creationTime | simpleDatePipe }}
        </td>
        <td>
          <nit-customer [ref]="$any($any(row.accountingDataRow$ | async)?.data$ | async)?.customerWsRef || undefined"
                        [showAccountingRef]="false"
                        [showName]="true"></nit-customer>
        </td>
        <td>
          {{ row.customerDocument.name }}
        </td>
        <td>
          <nit-document-status [value]="row.customerDocument.status!">
          </nit-document-status>
        </td>
      </tr>
    </ng-template>

  </p-table>

</div>
