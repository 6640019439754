import {WsCustomerDocumentSearch, WsCustomerDocumentSortField} from "@fiscalteam/nitro-domain-client";
import {FilterMetadata, SortMeta} from "primeng/api";
import {BehaviorSubject, combineLatest, distinctUntilChanged, forkJoin, Observable, of, shareReplay, Subscription, switchMap, Unsubscribable} from "rxjs";
import {debounceTime, filter, map} from "rxjs/operators";
import {CustomerDocumentColumn, CustomerDocumentFilterProperty, CustomerDocumentRow, CustomerDocumentService, FilterProperty, FilterPropertyValue, Pagination, PaginationUtils, TableHelper, TableHelperState} from "@fiscalteam/ngx-nitro-services";
import {WeightedColumn} from "@fiscalteam/ngx-nitro-services/lib/services/util/table/weighted-column";
import {Injectable} from "@angular/core";
import {WorkListStateService} from "../../service/work-list-state.service";
import {WorkListState} from "../../service/worklist/work-list-state";
import {WorkListItem} from "../../service/worklist/work-list-item";

@Injectable()
export class CustomerDocumentWorklistTableHelper implements Unsubscribable, TableHelper<WsCustomerDocumentSearch, WorkListItem, CustomerDocumentRow, CustomerDocumentColumn> {
  private columnsSource$ = new BehaviorSubject<CustomerDocumentColumn[]>([]);
  private subscription: Subscription;

  // Cached value we can saccess synchronously
  private workListState$ = new BehaviorSubject<WorkListState<any> | undefined>(undefined);
  private tableHelperState$: Observable<TableHelperState<CustomerDocumentColumn, WsCustomerDocumentSortField, CustomerDocumentFilterProperty, WsCustomerDocumentSearch>>;

  private rows$: Observable<CustomerDocumentRow[]>;
  private pagination$: Observable<Pagination<any, any>>;

  constructor(private workListService: WorkListStateService,
              private customerDocumentService: CustomerDocumentService,) {
    this.subscription = new Subscription();

    this.tableHelperState$ = combineLatest([
      this.getWeightedColumns$(),
      this.getFilterPatches$(),
      this.getPagination$(),
    ]).pipe(
      map(r => {
        const columns = r[0] as WeightedColumn<any>[];
        const filters = r[1] as FilterPropertyValue<any, any, any>[];
        const curPagination = r[2] as Pagination;
        const state: TableHelperState<CustomerDocumentColumn, WsCustomerDocumentSortField, CustomerDocumentFilterProperty, WsCustomerDocumentSearch> = {
          filters: filters,
          displayedColumns: columns.map(w => w.column),
          pagination: curPagination
        };
        return state;
      }),
      shareReplay({bufferSize: 1, refCount: true}),
    );

    this.rows$ = this.workListState$.pipe(
      map(s => s == null ? [] : s.pageItems),
      switchMap(items => this.loadRowModels$(items)),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.pagination$ = this.workListState$.pipe(
      map(s => this.createStatePagination(s)!),
      filter(p => p != null),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }


  subscribe() {
    const stateSubscription = this.workListService.getWorkListState$()
    .pipe(
      debounceTime(100),
      distinctUntilChanged(),
    )
    .subscribe(s => {
      this.workListState$.next(s)
    });

    this.subscription.add(stateSubscription);
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }

  applyColumnFiltersMetadata(filters: { [p: string]: FilterMetadata[] }): void {
  }

  getColumnFiltersMetadata$(): Observable<{ [p: string]: FilterMetadata[] }> {
    return of({});
  }

  getWeightedColumns$(): Observable<WeightedColumn<CustomerDocumentColumn>[]> {
    return this.columnsSource$.pipe(
      map(cols => cols.map(c => {
        return {
          column: c,
          widthPercent: 10,
        } as WeightedColumn<CustomerDocumentColumn>
      }))
    );
  }

  getColumnsProperties$(): Observable<FilterProperty<CustomerDocumentFilterProperty, WsCustomerDocumentSearch, any>[]> {
    return this.getWeightedColumns$().pipe(
      map(cols => {
        return cols.filter(c => c.column.filterProperty != null).map(c => c.column.filterProperty!)
      })
    );
  }

  setWeightedColumns(value: WeightedColumn<CustomerDocumentColumn>[]): void {
    const columns = value.map(w => w.column);
    this.setColumns(columns);
  }

  getCurPagination(): Pagination | undefined {
    const state: WorkListState<any> | undefined = this.workListState$.getValue();
    return this.createStatePagination(state);
  }

  getCurSearchFilter(): WsCustomerDocumentSearch | undefined {
    const state: WorkListState<any> | undefined = this.workListState$.getValue();
    if (state && state.workListFilter) {
      return state.workListFilter;
    } else {
      return undefined;
    }
  }

  getLoading$(): Observable<boolean> {
    return this.workListService.isLoading$();
  }

  getPageRowModels$(): Observable<CustomerDocumentRow[]> {
    return this.rows$;
  }

  getPagination$(): Observable<Pagination> {
    return this.pagination$;
  }

  getPaginationSortMetas$(): Observable<SortMeta[]> {
    return this.getPagination$().pipe(
      map(p => PaginationUtils.getMultiStortMeta(p)),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  getSearchFilter$(): Observable<WsCustomerDocumentSearch | undefined> {
    return this.workListState$.pipe(
      map(s => s?.workListFilter?.filter),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  getTotalRowCount$(): Observable<number> {
    return this.workListState$.pipe(
      map(s => s == null ? 0 : s.totalCount),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  reload(pagination?: Pagination): void {
    const state: WorkListState<any> | undefined = this.workListState$.getValue();
    if (state) {
      this.workListService.setWorkListPageOffset(state.pageOffset);
    }
  }

  search(pagination: Pagination, searchFilter?: WsCustomerDocumentSearch): void {
    const nextPagination = pagination || this.getCurPagination();
    if (nextPagination) {
      this.workListService.setWorkListPageOffset(nextPagination.first);
    }
  }

  setColumns(columns: CustomerDocumentColumn[]): void {
    this.columnsSource$.next(columns);
  }

  getFilterPatches$(): Observable<FilterPropertyValue<any, WsCustomerDocumentSearch, any>[]> {
    return of([]);
  }

  setFilterPatches(value: FilterPropertyValue<any, WsCustomerDocumentSearch, any>[]): void {
  }

  getTableState$(): Observable<TableHelperState<CustomerDocumentColumn, WsCustomerDocumentSortField, CustomerDocumentFilterProperty, WsCustomerDocumentSearch>> {
    return this.tableHelperState$;
  }

  private createStatePagination(state: WorkListState<any> | undefined) {
    if (state && state.workListFilter) {
      return PaginationUtils.newPagination(state.pageOffset, state.workListFilter.pageSize, state.workListFilter.sorts);
    } else {
      return undefined;
    }
  }

  private loadRowModels$(items: WorkListItem[]) {
    const row$List = items.map(item => this.loadRowModel$(item))
    return row$List.length === 0 ? of([]) : forkJoin(row$List);
  }

  private loadRowModel$(item: WorkListItem) {
    return this.customerDocumentService.getCustomerDocumentRow$(item.document);
  }
}
