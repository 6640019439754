import {Component, ElementRef, Host, Input, OnDestroy, OnInit} from '@angular/core';
import {FieldValueType, NitroLoggedUserService, SortingDocumentIndexingState} from '@fiscalteam/ngx-nitro-services';
import {WsFieldProblemType, WsRefWsTrustee} from '@fiscalteam/nitro-domain-client';
import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {IndexerDocumentFormFieldModel} from "../document-fields-form/indexer-document-form-field-model";
import {DocumentIndexingStateService} from "../document-fields-form/document-indexing-state-service";

@Component({
  selector: 'idx-document-field',
  templateUrl: './document-field.component.html',
  styleUrls: ['./document-field.component.scss'],
})
export class DocumentFieldComponent implements OnInit, OnDestroy {

  @Input()
  set formFieldModel(value: IndexerDocumentFormFieldModel | undefined) {
    this.fieldModel = value;
    if (value) {
      this.fieldTypedValue$ = value.fieldModel.anyTypedValue$;
    } else {
      this.fieldTypedValue$ = of(undefined);
    }
  }

  fieldModel: IndexerDocumentFormFieldModel | undefined;
  fieldTypedValue$: Observable<FieldValueType | undefined> = EMPTY;

  @Input()
  readOnly: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  showInfoMessage: boolean = true;
  @Input()
  countryCode?: string = undefined;

  trusteeRef?: WsRefWsTrustee | undefined;
  indexingState$: Observable<SortingDocumentIndexingState | undefined> = of(undefined);

  busy$ = new BehaviorSubject<boolean>(false);
  submitErrors?: string[];


  WsFieldProblemType = WsFieldProblemType;

  constructor(
    private loggedUserService: NitroLoggedUserService,
    private indexingStateService: DocumentIndexingStateService,
    @Host() private elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.trusteeRef = this.loggedUserService.getActiveTrusteeRef();
    this.indexingState$ = this.indexingStateService.indexingState$;
  }

  ngOnDestroy() {
  }

  focusInput() {
    if (this.elementRef && this.elementRef.nativeElement) {
      const htmlElement: HTMLElement = this.elementRef.nativeElement;
      const inputElement: HTMLInputElement | null = htmlElement.querySelector('input, button');
      if (inputElement) {
        inputElement.focus();
      } else if (htmlElement) {
        htmlElement.focus({});
      }
    }
  }

  onNoValueFoundCLick(eventValue: string, problemType: WsFieldProblemType) {
    if (this.fieldModel) {
      this.fieldModel.fieldModel.noValueFoundSource$.next({
        problemType: problemType,
        inputStringValue: eventValue
      });
    }
  }
}
