import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {AppShellComponent} from "./shell/app-shell/app-shell.component";
import {IndexerFrontAppRoutes} from "./shell/indexer-front-routes";
import {ErrorComponent, ErrorRouteComponent, ifTrusteeInContext, ifUserAuthenticated, LogoutRouteComponent, TrusteeContextWrapperRouteComponent} from "@fiscalteam/ngx-nitro-services";

const routes: Route[] = [
  {
    // Authenticated users without the required role, isplay the logout page
    path: 'logout',
    component: LogoutRouteComponent,
  },
  {
    // Authenticated users can see the fatal error pages
    path: 'error',
    component: ErrorRouteComponent,
  },
  {
    // Authenticated users can see the fatal error pages
    path: 'auth/error',
    component: ErrorComponent,
  },

  {
    path: '',
    canActivate: [ifUserAuthenticated(['trustee', 'admin'])],
    children: [{
      path: '',
      component: TrusteeContextWrapperRouteComponent,
      data: {
        allowAdmin: true,
        showLogout: true,
      },
      children: [{
        // Authenticated users with a trustee context: display the application
        path: '',
        canActivate: [ifTrusteeInContext(true)],
        component: AppShellComponent,
        children: IndexerFrontAppRoutes,
      }]
    }]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
