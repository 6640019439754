import {Injectable} from "@angular/core";
import {concat, EMPTY, merge, Observable, of, scan, shareReplay, switchMap} from "rxjs";
import {WsCustomerDocumentStatus, WsFieldIdentificationRequestStatus, WsIndexingFieldType, WsThirdPartyIdentification} from "@fiscalteam/nitro-domain-client";
import {map} from "rxjs/operators";
import {ErrorService, FieldValueType, IndexingFieldHandlersService, SortingDocumentIndexingState} from "@fiscalteam/ngx-nitro-services";
import {IndexerDocumentFormService} from "./indexer-document-form.service";
import {IndexerDocumentFormModel} from "./indexer-document-form-model";
import {IndexerDocumentFormFieldModel} from "./indexer-document-form-field-model";

@Injectable()
export class DocumentIndexingStateService {

  /**
   * The sorting indexing state for the active document
   */
  indexingState$: Observable<SortingDocumentIndexingState> = EMPTY;

  constructor(
    private fieldHandlerService: IndexingFieldHandlersService,
    private errorService: ErrorService,
    private indexerDocumentFormService: IndexerDocumentFormService,
  ) {
    // Whenever we have a new form model, we create a new indexing state
    this.indexingState$ = this.indexerDocumentFormService.getFormModel$().pipe(
      switchMap(m => this.createIndexingState$(m)),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  private createIndexingState$(model: IndexerDocumentFormModel | undefined): Observable<SortingDocumentIndexingState> {
    if (model == null || model.fieldModels.length < 1) {
      return of({});
    }
    const doc = model.document;
    if (doc.status !== WsCustomerDocumentStatus.Created) {
      return of({});
    }

    // We create an indexing state by applying multiple updates sequentially.
    let stateUpdates$List: Observable<Partial<SortingDocumentIndexingState>>[] = [of({
      documentRef: {id: doc.id!},
    })];

    // If we have a field for thirparty recipient, update indexing state
    let thirdpartyRecipientModel = model.fieldModels.find(m => m.fieldModel.field.fieldType === WsIndexingFieldType.ThirdpartyRecipient);
    if (thirdpartyRecipientModel) {
      const thirdpartyrecipientUpdates$ = this.createdIndexingStateFieldUpdate$(thirdpartyRecipientModel, i => {
        return {
          thirdpartyToIdentification: i as WsThirdPartyIdentification
        };
      });
      stateUpdates$List.push(thirdpartyrecipientUpdates$);
    }

    // If we have a field for thirparty emitter, update indexing state
    let thirdpartyEmitterModel = model.fieldModels.find(m => m.fieldModel.field.fieldType === WsIndexingFieldType.ThirdpartyEmitter);
    if (thirdpartyEmitterModel) {
      const thirdpartyEmitterUpdates$ = this.createdIndexingStateFieldUpdate$(thirdpartyEmitterModel, i => {
        return {
          thirdpartyFromIdentification: i as WsThirdPartyIdentification
        };
      });
      stateUpdates$List.push(thirdpartyEmitterUpdates$);
    }

    // If we have a field for document type, update indexing state
    let documentTypeModel = model.fieldModels.find(m => m.fieldModel.field.fieldType === WsIndexingFieldType.DocumentType);
    if (documentTypeModel) {
      const documentTypeUpdates$ = this.createdIndexingStateFieldUpdate$(thirdpartyEmitterModel, i => {
        return {
          thirdpartyFromIdentification: i as WsThirdPartyIdentification
        };
      });
      stateUpdates$List.push(documentTypeUpdates$);
    }

    // We do not process further at the moment as this state is only used to suggest documentTypes.
    // TODO: generify this logic for all fields

    // We apply each of those values sequentially using the scan operator
    return merge(...stateUpdates$List).pipe(
      scan((curState, nextUpdate) => Object.assign({}, curState, nextUpdate), {} as SortingDocumentIndexingState),
    );
  }

  private createdIndexingStateFieldUpdate$(fieldModel: IndexerDocumentFormFieldModel | undefined,
                                           mapper: (i: FieldValueType | undefined) => Partial<SortingDocumentIndexingState>): Observable<Partial<SortingDocumentIndexingState>> {
    if (fieldModel == null) {
      return of({});
    }
    // Find the initial value: use this indexer value if it exists, otherwise the field request value
    const indexerValue = fieldModel.fieldModel.indexerValue$.getValue();
    let initialValue$: Observable<FieldValueType | undefined>;
    if (indexerValue) {
      initialValue$ = this.fieldHandlerService.fetchFieldValue$({id: indexerValue.id!}, fieldModel.fieldModel.field);
      // NITRO-459: if we have an indentified value by other indexers, use that
    } else if (fieldModel.fieldModel.fieldRequest.requestStatus === WsFieldIdentificationRequestStatus.Indexed) {
      initialValue$ = this.fieldHandlerService.fetchFieldRequestValue$(fieldModel.fieldModel.fieldRequest, fieldModel.fieldModel.field);
    } else {
      initialValue$ = of(undefined);
    }

    // whenever the field value is emitted in the model, apply the change to the indexing state as well.
    const updatedValues$: Observable<FieldValueType | undefined> = fieldModel.fieldModel.typedValueUpdateSource$;
    return concat(initialValue$, updatedValues$).pipe(
      map((i: FieldValueType | undefined) => mapper(i)),
    );
  }

}
