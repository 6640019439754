import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {nitroConfigWithDomainApiUri, tryLoadNitroBackendUriFromJson} from "@fiscalteam/ngx-nitro-services";

if (environment.production) {
  enableProdMode();
}

tryLoadNitroBackendUriFromJson(environment.domainAppUri).then(apiUri => {
  return platformBrowserDynamic([
    nitroConfigWithDomainApiUri(apiUri)
  ])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
