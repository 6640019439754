import {Injectable} from "@angular/core";
import {ErrorService, FieldIdentificationRequestService, FieldIdentificationValueService, FieldValueType, IndexingFieldModel, NitroLoggedUserService, PaginationUtils} from "@fiscalteam/ngx-nitro-services";
import {BehaviorSubject, concat, forkJoin, Observable, of, switchMap, take, throwError} from "rxjs";
import {IndexerDocumentFormModel} from "./indexer-document-form-model";
import {WsCustomerDocument, WsFieldIdentificationRequest, WsFieldIdentificationRequestSearch, WsFieldIdentificationRequestSortField, WsFieldIdentificationRequestStatus, WsFieldIdentificationValue, WsFieldIdentificationValueStatus, WsFieldProblemType, WsIndexingFieldType} from "@fiscalteam/nitro-domain-client";
import {catchError, map} from "rxjs/operators";
import {IndexerDocumentFormFieldModel} from "./indexer-document-form-field-model";
import {IndexerDocumentFormFieldService} from "./indexer-document-form-field.service";
import {IndexerFrontConfigService} from "../../../config/indexer-front-config.service";
import {WorkListItem} from "../../../service/worklist/work-list-item";

@Injectable()
export class IndexerDocumentFormService {

  private formModel$ = new BehaviorSubject<IndexerDocumentFormModel | undefined>(undefined);

  constructor(
    private loggedUserService: NitroLoggedUserService,
    private fieldRequestService: FieldIdentificationRequestService,
    private fieldValueService: FieldIdentificationValueService,
    private documentFormFieldService: IndexerDocumentFormFieldService,
    private configService: IndexerFrontConfigService,
    private errorService: ErrorService,
  ) {
  }

  createFormModel$(item: WorkListItem | undefined): Observable<IndexerDocumentFormModel | undefined> {
    const fieldRequests$ = this.fetchFieldRequestsForWorkitem$(item);
    const document$ = item == null ? of(undefined) : of(item.document);
    return forkJoin([document$, fieldRequests$]).pipe(
      switchMap((r: [WsCustomerDocument | undefined, WsFieldIdentificationRequest[]]) => this.createNewFormModel$(item, r[0], r[1]))
    )
  }

  setFormModel(formModel: IndexerDocumentFormModel | undefined) {
    const curForm = this.formModel$.getValue();
    if (curForm === formModel) {
      return;
    }

    if (curForm != null) {
      // Unsubscribe all previous field synchronization
      curForm.fieldModels.forEach(m => {
        if (m.indexerValuesSyncSubscription) {
          m.indexerValuesSyncSubscription.unsubscribe();
        }
      });

      this.documentFormFieldService.displayField(undefined);

      if (curForm.document.id !== curForm.document.id) {
        // We switched to another document. Prune values if required
        this.pruneFormValues(curForm);
      }
    }
    this.formModel$.next(formModel);

    if (formModel) {
      this.documentFormFieldService.onNewFormLoaded(formModel);
    }
  }

  hasOtherFieldsToIndex(formModel: IndexerDocumentFormModel | undefined,
                        displayedField: IndexerDocumentFormFieldModel | undefined): boolean {
    if (formModel == null) {
      return false;
    }
    return this.filterFormFieldsModels(formModel, f => {
      if (this.documentFormFieldService.isFieldIndexingRequired(f)) {
        return true;
      }
      return false;
    })
      .filter(m => m !== displayedField).length > 0;
  }


  getOtherIndexedFields(formModel: IndexerDocumentFormModel | undefined, displayedField: IndexerDocumentFormFieldModel | undefined) {
    if (formModel == null) {
      return [];
    }
    const otherFields = this.filterFormFieldsModels(formModel, f => {
      const indexerBackendValue = f.fieldModel.indexerValue$.getValue();
      const valueSubmitted = indexerBackendValue && indexerBackendValue.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
      const requestSubmitted = f.fieldModel.fieldRequest.requestStatus !== WsFieldIdentificationRequestStatus.WaitingForIndexing;
      const hasIdentifiedValueOnBackend = indexerBackendValue && indexerBackendValue.identifiedValue && indexerBackendValue.identifiedValue.length > 0;
      if (valueSubmitted || requestSubmitted) {
        return true;
      }
      const hasIndexerValue = f.fieldModel.isIndexerFilledNow() || hasIdentifiedValueOnBackend;
      if (hasIndexerValue) {
        return true;
      }
      const committedToSubmit = f.pendingSubmitLocalChanges && f.pendingSubmitLocalChanges.valueStatus && f.pendingSubmitLocalChanges.valueStatus !== WsFieldIdentificationValueStatus.Displayed;
      if (committedToSubmit) {
        return true;
      }
      return false;
    })
      .filter(m => m !== displayedField);
    return otherFields;
  }

  getFormModel$(): Observable<IndexerDocumentFormModel | undefined> {
    return this.formModel$.asObservable();
  }

  private filterFormFieldsModels(formModel: IndexerDocumentFormModel | undefined,
                                 predicate: (field: IndexerDocumentFormFieldModel) => boolean) {
    if (formModel == null) {
      return [];
    }
    return formModel.fieldModels.filter(f => predicate(f));
  }

  private fetchFieldRequestsForWorkitem$(item: WorkListItem | undefined): Observable<WsFieldIdentificationRequest[]> {
    const trusteeRef = this.loggedUserService.getActiveTrusteeRef();
    if (item && item.document && item.document.id) {
      const requetsSearch: WsFieldIdentificationRequestSearch = {
        customerDocumentSearch: {
          exactWsCustomerDocumentWsRef: {id: item.document.id},
          accountingDataSearch: {
            customerSearch: {
              trusteeSearch: {
                exactTrusteeWsRef: trusteeRef,
              },
            }
          },
        },
      }
      const pagination = PaginationUtils.newPagination(0, 100, [{
        field: WsFieldIdentificationRequestSortField.DisplayOrder,
        order: "asc"
      }]);

      const fields$ = this.fieldRequestService.searchFieldIdentificationRequests$(requetsSearch, pagination).pipe(
        map(r => r.itemList || []),
      );
      // Emit empty list right away, then emit the actual fields. This prevents the old fields to remain displayed while building the new models.
      return concat(of([]), fields$);
    } else {
      return of([]);
    }
  }

  private createNewFormModel$(item: WorkListItem | undefined,
                              document: WsCustomerDocument | undefined,
                              requests: WsFieldIdentificationRequest[]): Observable<IndexerDocumentFormModel | undefined> {
    if (item == null || document == null) {
      return of(undefined);
    }

    if (requests.length === 0) {
      return this.createEmptyIndexerFormModel$(item, document);
    }

    const loggedUser = this.loggedUserService.getLoggedUserOrThrow();
    const fieldModel$List = requests
      .map(req => this.fieldValueService.createIndexingFieldModel$({id: req.id!}, {id: loggedUser.id!}, true,
        req.requestStatus === WsFieldIdentificationRequestStatus.WaitingForIndexing));
    return forkJoin(fieldModel$List).pipe(
      map((models: IndexingFieldModel[]) => this.createFormFieldModels(models)),
      switchMap(models => this.createIndexerFormModel$(item, document, models)),
    )
  }

  private createEmptyIndexerFormModel$(item: WorkListItem, document: WsCustomerDocument): Observable<IndexerDocumentFormModel> {
    const model: IndexerDocumentFormModel = {
      workListItem: item,
      document: document,
      fieldModels: []
    };
    return of(model);
  }

  private createIndexerFormModel$(item: WorkListItem, document: WsCustomerDocument, fieldModels: IndexerDocumentFormFieldModel[]): Observable<IndexerDocumentFormModel> {
    const model: IndexerDocumentFormModel = {
      workListItem: item,
      document: document,
      fieldModels: fieldModels,
    };
    return of(model);
  }

  private createFormFieldModels(models: IndexingFieldModel[]): IndexerDocumentFormFieldModel[] {
    return models.map(model => this.createFormFieldModel(model));
  }

  private createFormFieldModel(model: IndexingFieldModel) {
    let problemTypeWhitelist: WsFieldProblemType[] | undefined = undefined;
    if (model.field.fieldType === WsIndexingFieldType.ThirdpartyRecipient
      || model.field.fieldType === WsIndexingFieldType.ThirdpartyEmitter) {
      // NITRO-456: Remove  Le tiers n'est pas répertorié de la liste des problème
      problemTypeWhitelist = [
        WsFieldProblemType.ThirdpartyNotIdentifiable
      ];
    } else if (model.field.fieldType === WsIndexingFieldType.PaymentIban) {
      problemTypeWhitelist = [];
    }

    const formFieldModel: IndexerDocumentFormFieldModel = {
      fieldModel: model,
      indexerValuesSyncSubscription: undefined,
      pendingSubmitLocalChanges: {},
      problemTypeWhitelist: [] // Tempoarary don't allow non-well handled problem types
    };
    return formFieldModel;
  }

  private pruneFormValues(formModel: IndexerDocumentFormModel) {
    const prunevalues = this.configService.isFeatureEnabled("document.placeholderValues.prune");
    if (prunevalues && formModel && formModel.document) {
      this.clearTemporaryDocValues$(formModel).subscribe();
    }
  }


  private clearTemporaryDocValues$(formModel: IndexerDocumentFormModel): Observable<IndexerDocumentFormModel> {
    if (formModel == null) {
      return throwError(() => new Error(`no model`));
    }
    const fieldModels = formModel.fieldModels;
    const persistedDisplayedIndexerModels = fieldModels.filter(m => {
      const indexerValue = m.fieldModel.indexerValue$.getValue();
      return indexerValue != null && indexerValue.valueStatus === WsFieldIdentificationValueStatus.Displayed && indexerValue.id != null;
    });

    const modelWIthLastValues$List = persistedDisplayedIndexerModels.map(m => {
      const lastValue$ = m.fieldModel.typedValueUpdateSource$.pipe(
        take(1),
      );
      return lastValue$.pipe(
        map(v => {
          return {model: m, lastValue: v};
        }),
      )
    });
    const modelWithLastValueList$ = modelWIthLastValues$List.length === 0 ? of([]) : forkJoin(modelWIthLastValues$List);
    return modelWithLastValueList$.pipe(
      switchMap((models: { model: IndexerDocumentFormFieldModel, lastValue: FieldValueType | undefined }[]) => {
        const modelsWithLastValue = models.filter(m => m.lastValue != null);
        const modelWithoutValue = models.filter(m => m.lastValue == null);

        const savedValues$List = modelsWithLastValue.map(v => this.saveDocAValueWhenSwitchingToDocB$(formModel, v.model));
        const savedValue$ = savedValues$List.length === 0 ? of([]) : forkJoin(savedValues$List);

        const removedValues$List = modelWithoutValue.map(v => this.deleteDocAValueWhenSwitchingToDocB(formModel, v.model));
        const removedValues$ = removedValues$List.length === 0 ? of([]) : forkJoin(removedValues$List);

        return forkJoin([savedValue$, removedValues$]);
      }),
      map(a => formModel),
    );
  }

  private deleteDocAValueWhenSwitchingToDocB(formModel: IndexerDocumentFormModel, fieldModel: IndexerDocumentFormFieldModel) {
    if (formModel && fieldModel && fieldModel.fieldModel.indexerValue$.getValue()) {
      const indexerValue: WsFieldIdentificationValue = fieldModel.fieldModel.indexerValue$.getValue()!;
      return this.fieldValueService.deleteFieldIdentificationValue$({id: indexerValue!.id!}).pipe(
        catchError(e => {
          const message = this.errorService.getAnyErrorMessage(e);
          const docId = formModel.document?.id;
          const newError = new Error(`Ignoring save error when switching from doc ${docId}: ${message}`);
          // Make sure backend is notified
          this.errorService.handleError(newError);
          return of(undefined); // Discard
        }),
      )
    } else {
      return of(undefined);
    }
  }

  private saveDocAValueWhenSwitchingToDocB$(formModel: IndexerDocumentFormModel, fieldModel: IndexerDocumentFormFieldModel) {
    if (formModel && fieldModel && fieldModel.fieldModel.indexerValue$.getValue()) {
      const indexerValue: WsFieldIdentificationValue = fieldModel.fieldModel.indexerValue$.getValue()!;
      return this.fieldValueService.saveFieldIdentificationValueHandlingErrors$(indexerValue, true, true).pipe(
        catchError(e => {
          const message = this.errorService.getAnyErrorMessage(e);
          const docId = formModel.document?.id;
          const newError = new Error(`Ignoring save error when switching from doc ${docId}: ${message}`);
          // Make sure backend is notified
          this.errorService.handleError(newError);
          return of(undefined); // Discard
        }),
      );
    } else {
      return of(undefined); // Discard
    }
  }

}
