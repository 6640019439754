import {Component, OnInit} from '@angular/core';
import {Message, MessageService} from "primeng/api";
import {firstValueFrom, Observable, of} from "rxjs";
import {NitroLoggedUserService, NitroMessageService, TrusteeService} from "@fiscalteam/ngx-nitro-services";
import {AppShellModalHelpers} from "./app-shell-modal-helpers";
import {VERSION} from "../../../version";
import {WsNitroUser, WsRefWsTrustee, WsTrustee} from "@fiscalteam/nitro-domain-client";
import {ActivatedRoute, Router} from "@angular/router";
import {WorkListService} from "../../service/work-list-service";

@Component({
  selector: 'idx-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  providers: [MessageService]
})
export class AppShellComponent implements OnInit {

  messages$: Observable<Message[]> = of([]);

  ModalKeys = AppShellModalHelpers;

  version = VERSION;
  loggedUser: WsNitroUser | undefined = undefined;
  trustee: WsTrustee | null = null;
  canChangeTrustee: boolean = false;


  constructor(
    private nitroLoggedUserService: NitroLoggedUserService,
    private messageService: NitroMessageService,
    private trusteeService: TrusteeService,
    private primengMessageService: MessageService,
    private worklistService: WorkListService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  async ngOnInit(): Promise<void> {
    // Display messages from message service
    this.messages$ = this.messageService.getMessages$();

    // Display logged user & its trustee
    this.loggedUser = await this.nitroLoggedUserService.getLoggedUser$();
    if (this.nitroLoggedUserService.hasTrusteeSet()) {
      const trusteeRef = this.nitroLoggedUserService.getActiveTrusteeRef() as WsRefWsTrustee;
      this.trustee = (await firstValueFrom(this.trusteeService.getTrustee$(trusteeRef))) ?? null;
    } else {
      this.trustee = null;
    }
    this.canChangeTrustee = this.nitroLoggedUserService.getAvailableTrusteeUsers().length > 1 || (this.loggedUser?.nitroAdmin || false);

    // Initialize the work list based on query params
    this.startInitialWorkList();

  }

  async onLogoutClick() {
    await this.nitroLoggedUserService.logout('/');
  }

  onChangeTrusteeClick() {
    this.nitroLoggedUserService.clearActiveTrusteeRef();
  }

  discardMessages() {
    this.messageService.discardAll();
  }

  onTitleClick() {
    this.startInitialWorkList();
    this.router.navigate(['/']);
  }

  onOptimsticLockModalClose() {
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  onOptimsticLockRefetch(message: any) {
    AppShellModalHelpers.callOptimisticLockReloadCallback(message);
    this.primengMessageService.clear(AppShellModalHelpers.OPTIMISTIC_LOCK_MODAL_KEY);
  }

  private startInitialWorkList() {
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    this.worklistService.initStartWorkList(queryParams);
  }

}
