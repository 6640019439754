import {Component} from '@angular/core';

@Component({
  selector: 'idx-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor() {

  }
}
