import {EnvConfig} from "./env-config";

export const environment: EnvConfig = {
  production: true,
  domainAppUri: 'https://nitro.local:8443/domain-ws',
  runtimeConfigPath: '/config/nitro-config.json',
  configValues: {
    'front.error.notify.backend.enabled': true,
    'front.auth.issuer': 'https://sso.nitro.valuya.be/realms/nitro',
    "front.auth.clientId": 'nitro-indexer-front',
  }
};
