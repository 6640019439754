import {Injectable} from "@angular/core";
import {ConfigService} from "@fiscalteam/ngx-nitro-services";
import {IndexerFrontConfigKeys} from "./indexer-front-config-keys";

/**
 * A service to resolve this application's configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class IndexerFrontConfigService {

  private runtimeIndexerAppFeatures: Record<IndexerFrontConfigKeys, boolean>;

  constructor(
    private nitroConfigService: ConfigService,
  ) {
    this.runtimeIndexerAppFeatures = this.configureRuntimeAppFeatures();
    console.log([`Configured indexer front features`, this.runtimeIndexerAppFeatures]);
  }

  isFeatureEnabled(indexerAppFeature: IndexerFrontConfigKeys): boolean {
    if (this.runtimeIndexerAppFeatures[indexerAppFeature] != null) {
      return this.runtimeIndexerAppFeatures[indexerAppFeature];
    } else {
      return this.nitroConfigService.getConfigValue(indexerAppFeature) === "true";
    }
  }


  private configureRuntimeAppFeatures(): Record<IndexerFrontConfigKeys, boolean> {
    return {} as Record<IndexerFrontConfigKeys, boolean>;
  }
}
