import {BehaviorSubject, combineLatest, EMPTY, forkJoin, merge, Observable, of, scan, shareReplay, Subject, Subscription, switchMap} from "rxjs";
import {WorkListState} from "./worklist/work-list-state";
import {WorkListNavigationState} from "./worklist/work-list-navigation-state";
import {debounceTime, map} from "rxjs/operators";
import {WorkListItem} from "./worklist/work-list-item";
import {Injectable} from "@angular/core";
import {WorkListStateService} from "./work-list-state.service";

/**
 * This service manages a work list navigation state.
 *
 * Provided work list states, it will navigate through its item list, and emit a new 'navigation state' for each
 * item navigated.
 * When a new work list page should be loaded to continue navigation, then an event will be emitted.
 * When no more item can be navigated to, the
 *
 * When required, this service can also preload the document content, and request the next work list page, a few items
 * ahead. Similarly, this service can remember last navigated items.
 */
@Injectable({
  providedIn: 'root'
})
export class WorkListNavigationStateService {

  // CONSTANTS
  private readonly HISTORY_MAX_SIZE = 10;
  private readonly WORKLIST_STATE_MAX_SIZE = 100;
  private readonly PRELOAD_DEBOUNCE_TIME_MS = 1000;


  // INPUTS
  /**
   * The absolute item index, or offset, within the work list.
   * @private
   */
  private itemIndexSource$ = new BehaviorSubject<number>(0);
  /**
   * A work list item to navigate to directly, ignoring index
   * @private
   */
  private itemSource$ = new Subject<WorkListItem>();


  private readonly navigationState$: Observable<WorkListNavigationState<any>>;


  constructor(
    private workListStateService: WorkListStateService,
  ) {
    // Combine received work lists - provided they are compatible, in order to keep 2 pages in memory to ease transition
    // between pages
    const combinedWorkListState$: Observable<WorkListState<any> | undefined> = this.workListStateService.getWorkListState$().pipe(
      scan((previousState: WorkListState<any> | undefined, nextState: WorkListState<any> | undefined) => this.combinedWorkListStates(previousState, nextState)),
      shareReplay({bufferSize: 1, refCount: true}),
    );
    const indexBasedNavigationItem$: Observable<WorkListNavigationState<any>> = combineLatest([
      this.itemIndexSource$,
      combinedWorkListState$,
    ]).pipe(
      debounceTime(0),// Force async
      switchMap(([itemIndex, workListState]) => this.createNavigationStateForItemIndex(itemIndex, workListState)),
    );
    const itemBasedNavigationItem$: Observable<WorkListNavigationState<any>> = combineLatest([
      this.itemSource$,
      combinedWorkListState$
    ]).pipe(
      debounceTime(0),// Force async
      switchMap(([item, workListState]) => this.createNavigationStateForItem(item, workListState)),
    );
    this.navigationState$ = merge(indexBasedNavigationItem$, itemBasedNavigationItem$).pipe(
      scan((cur, next) => this.amendHistory(cur, next)),
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  /**
   * Navigate to the item at the specified index within the work list (absolute offset, not within the active work list state/page)
   * @param index
   */
  navigateToItemIndex(index: number) {
    this.itemIndexSource$.next(index);
  }

  navigateToItemAtIndexDelta(indexDelta: number) {
    const curIndex = this.itemIndexSource$.getValue();
    if (curIndex >= 0 && (curIndex + indexDelta) >= 0) {
      this.itemIndexSource$.next(curIndex + indexDelta);
    } else {
      console.warn(`Invalid navigation index: ${curIndex} + ${indexDelta}. Resetting navigation to index 0`);
      this.itemIndexSource$.next(0);
    }
  }

  /**
   * Navigate to the specific work list item. If its index can be found within the loaded work list states, navigation can be resumed
   * from there afterwards. Otherwise, the index is lost and navigation can only be resumed by navigating to a dedicated index.
   * @param item
   */
  navigateToItem(item: WorkListItem) {
    this.itemSource$.next(item);
  }

  /**
   * Return an observable that emits the current navigation state, as well as the work list state built for navigation logic.
   * Multiple values might be emitted even though the active navigation item did not change. Make sure to use the
   * distinctUntilChanged operator as required.
   */
  getNavigationState$(): Observable<WorkListNavigationState<any>> {
    return this.navigationState$;
  }


  /**
   * Returns a subscription to preload a few items ahead.
   *
   * After navigating to item X, items X+1, X+2, ..., X+n will be subscribed to in order to preload the document
   * content in memory. The preloaded content can then be accessed using WorkListItem.docBytesBlob$. Events to
   * preload the next page of the work list will be emitted as well as required.
   *
   * When the current navigation state does not contain the active item index, no action will be performed.
   */
  subscribeToPreloadNextItemAhead(count: number = 3): Subscription {
    return this.navigationState$.pipe(
      debounceTime(this.PRELOAD_DEBOUNCE_TIME_MS),
      switchMap(navState => this.preloadForNavigatedItem$(navState, count)),
    ).subscribe()
  }

  private combinedWorkListStates(curState: WorkListState<any> | undefined, nextState: WorkListState<any> | undefined) {
    if (curState == null || nextState == null) {
      return nextState;
    }
    // Filter change
    if (curState.workListFilter !== nextState.workListFilter) {
      return nextState;
    }
    // Total count changed. We cant assume whether this affected any of the pages here, but the risk is to have
    // the same item at multiple offset; so we dont combine in that case
    if (curState.totalCount !== nextState.totalCount) {
      return nextState;
    }

    const combined = this.combineWorkListStates(curState, nextState, this.WORKLIST_STATE_MAX_SIZE);
    const newState = combined || nextState;

    // Ensure the navigation item is still in the work list in case we could not combine them
    if (combined == null) {
      const curNavigationIndex = this.itemIndexSource$.getValue();
      const minIndex = newState.pageOffset;
      const maxIndex = minIndex + newState.workListFilter.pageSize;
      if (curNavigationIndex < minIndex) {
        this.itemIndexSource$.next(minIndex);
      } else if (curNavigationIndex >= maxIndex) {
        this.itemIndexSource$.next(maxIndex - 1);
      }
    }
    return newState;
  }

  private combineWorkListStates(stateA: WorkListState<any>, stateB: WorkListState<any>, maxItemCount: number): WorkListState<any> | undefined {
    // Combine two worklist state (pages) that must be compatible (same filter + totalCount).
    // If the states do no represent consecutive pages, then we return undefined - combination cant occur.
    // Otherwise, we return a new state comprising all unique items between the states. If we notice the same item
    // at multiple offsets across the two provided state, then undefined is returned.
    const lowerState = stateA.pageOffset < stateB.pageOffset ? stateA : stateB;
    const higherState = stateA.pageOffset < stateB.pageOffset ? stateB : stateA;

    const lowerStateOffset = lowerState.pageOffset;
    const lowerStateCount = lowerState.pageItems.length;
    const higherStateOffset = higherState.pageOffset;
    // The offset within higher state items which are continuous to the last item of lower state
    const higherStateOffsetDiff = lowerStateOffset + lowerStateCount - higherStateOffset;
    if (higherStateOffsetDiff < 0) {
      // Higher state is not continuous with lower state
      return undefined;
    }

    const nextHigherStateItems = higherState.pageItems.slice(higherStateOffsetDiff);
    const hasDuplicateItem = nextHigherStateItems.find(nextItem => lowerState.pageItems.find(lowerItem => lowerItem === nextItem) != null) != null;
    if (hasDuplicateItem) {
      return undefined;
    }

    // The offset of lower state from which to keep items
    let minLowerStaateOffsetToKeep = higherStateOffset + higherState.pageItems.length - maxItemCount - lowerStateOffset;
    if (minLowerStaateOffsetToKeep < 0) {
      minLowerStaateOffsetToKeep = 0;
    }
    const keptLowerStateItems = lowerState.pageItems.slice(minLowerStaateOffsetToKeep, lowerStateCount);
    const combinedItems = [...keptLowerStateItems, ...nextHigherStateItems];
    return {
      workListFilter: stateA.workListFilter,
      pageItems: combinedItems,
      pageOffset: lowerStateOffset + minLowerStaateOffsetToKeep,
      totalCount: stateA.totalCount
    };
  }

  private createNavigationStateForItemIndex(itemIndex: number, workListState: WorkListState<any> | undefined) {
    if (itemIndex < 0) {
      throw new Error(`Invalid index`);
    }
    if (workListState == null) {
      const finalState = this.createFinalState(undefined);
      return of(finalState);
    }

    const withinWorkListStateItemOffset = itemIndex - workListState.pageOffset;
    const workListStateLength = workListState.pageItems.length;
    if (withinWorkListStateItemOffset < 0) {
      // Need to load some prior page - emit event to load page and ignore this navigation state
      // Attempt to load the previous page, otherwise the page starting at index
      const previousPageStart = workListState.pageOffset - workListState.workListFilter.pageSize;
      if (previousPageStart < itemIndex) {
        // Load previous page
        this.workListStateService.setWorkListPageOffset(previousPageStart);
      } else {
        // Load some prior page, and loose continuity with the latest loaded page, meaning all current WorkItem will
        // be dropped from memory and will need to be fetched again
        this.workListStateService.setWorkListPageOffset(itemIndex);
      }
      return EMPTY;
    } else if (withinWorkListStateItemOffset >= workListStateLength) {
      // Check if we reached end of worklist
      if (itemIndex >= workListState.totalCount) {
        const finalState = this.createFinalState(workListState);
        return of(finalState);
      } else {
        // Need to load next page - emit event and ignore this navigation state
        this.workListStateService.setWorkListPageOffset(itemIndex);
        return EMPTY;
      }
    }

    const activeItem = workListState.pageItems[withinWorkListStateItemOffset];
    const navigationState: WorkListNavigationState<any> = {
      activeItem: activeItem,
      itemIndex: itemIndex,
      itemWorkListStateIndex: withinWorkListStateItemOffset,
      workListState: workListState,
      navigationHistoryItems: []
    };
    return of(navigationState);
  }

  private createNavigationStateForItem(item: WorkListItem, workListState: WorkListState<any> | undefined) {
    if (workListState == null) {
      // No work list state - ignore this navigation state
      const finalState = this.createFinalState(undefined);
      return of(finalState);
    }

    // Attempt to find item in loaded work list state
    const workListItemIndex = workListState.pageItems.findIndex(workListItem => workListItem === item);
    const itemIndex = workListItemIndex == null ? undefined : workListState.pageOffset + workListItemIndex;

    const navigationState: WorkListNavigationState<any> = {
      activeItem: item,
      itemIndex: itemIndex,
      itemWorkListStateIndex: workListItemIndex,
      workListState: workListState,
      navigationHistoryItems: []
    };
    return of(navigationState);
  }

  private createFinalState(workListState: WorkListState<any> | undefined): WorkListNavigationState<any> {
    return {
      activeItem: undefined,
      itemIndex: undefined,
      itemWorkListStateIndex: undefined,
      workListState: workListState,
      navigationHistoryItems: []
    }
  }


  private amendHistory(curState: WorkListNavigationState<any>, nextState: WorkListNavigationState<any>): WorkListNavigationState<any> {
    let newHistory = [...curState.navigationHistoryItems];
    if (curState.activeItem) {
      newHistory.push(curState.activeItem);
    }
    if (newHistory.length > this.HISTORY_MAX_SIZE) {
      const fromIndex = newHistory.length - this.HISTORY_MAX_SIZE;
      newHistory = newHistory.slice(fromIndex);
    }
    return Object.assign({}, nextState, {
      navigationHistoryItems: newHistory
    });
  }


  private preloadForNavigatedItem$(navState: WorkListNavigationState<any>, count: number) {
    const itemIndex = navState.itemIndex;
    if (itemIndex == null) {
      return EMPTY;
    }

    const workListState = navState.workListState;
    if (workListState == null) {
      return EMPTY;
    }

    const lastLoadedStateIndex = workListState.pageOffset + workListState.pageItems.length;
    let lasIndexToLoad = itemIndex + count;
    if (lasIndexToLoad >= workListState.totalCount) {
      // No more items to load
      return EMPTY;
    }
    if (lastLoadedStateIndex <= lasIndexToLoad) {
      // Need to load next page
      this.workListStateService.setWorkListPageOffset(lastLoadedStateIndex)
      return EMPTY;
    }

    const workListStateItemIndex = navState.itemWorkListStateIndex;
    if (workListStateItemIndex == null) {
      console.warn(`No work list state index: ${workListStateItemIndex}`);
      return EMPTY;
    }
    const loadedItem$TaskList = [];
    for (let i = 1; i <= count; i++) {
      const item = workListState.pageItems[workListStateItemIndex + i];
      const loadedItem$ = this.preloadItem$(item);
      loadedItem$TaskList.push(loadedItem$);
    }

    return forkJoin(loadedItem$TaskList);
  }

  private preloadItem$(item: WorkListItem) {
    return forkJoin([
      item.accountData$,
      item.docBytesBlob$
    ]).pipe(
      map(r => true),
    );
  }
}
