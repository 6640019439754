import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable, shareReplay} from "rxjs";
import {SelectItem} from "primeng/api";
import {CustomerAutocompleteModule, NitroLoggedUserService} from "@fiscalteam/ngx-nitro-services";
import {map} from "rxjs/operators";
import {AsyncPipe, NgForOf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {WorkListService} from "../service/work-list-service";
import {Router, RouterLink} from "@angular/router";
import {WorkListFilter} from "../service/worklist/work-list-filter";
import {DialogModule} from "primeng/dialog";
import {FormsModule} from "@angular/forms";
import {WsRefWsCustomer, WsRefWsTrustee} from "@fiscalteam/nitro-domain-client";
import {SplitButtonModule} from "primeng/splitbutton";

@Component({
  selector: 'idx-worklist-select',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonModule,
    NgForOf,
    OverlayPanelModule,
    RouterLink,
    DialogModule,
    CustomerAutocompleteModule,
    FormsModule,
    SplitButtonModule
  ],
  templateUrl: './worklist-select.component.html',
  styleUrl: './worklist-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorklistSelectComponent implements OnInit {

  activeWorkListName$?: Observable<string>;
  availableWorkListMenu?: SelectItem<WorkListFilter<any, any>>[];
  customerSelectVisible: boolean = false;

  trusteeRef?: WsRefWsTrustee;

  constructor(
    private nitroLoggedUserService: NitroLoggedUserService,
    private workListService: WorkListService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const loggedUser = this.nitroLoggedUserService.getLoggedUserOrThrow();
    this.trusteeRef = this.nitroLoggedUserService.getActiveTrusteeRef();

    this.activeWorkListName$ = this.workListService.getActiveWorkListFilter$().pipe(
      map(filter => filter.label),
      shareReplay({refCount: true, bufferSize: 1}),
    );
    this.availableWorkListMenu = this.workListService.createWorkListMenuOptions(loggedUser, this.trusteeRef);
  }

  onItemClick(item: SelectItem<WorkListFilter<any, any>>) {
    this.workListService.initNewWorkList(item.value);
    this.router.navigate(['/_']);
  }

  onCustomerChosen(customer: WsRefWsCustomer) {
    const loggedUser = this.nitroLoggedUserService.getLoggedUserOrThrow();
    let newWorkListFilter;
    if (loggedUser.nitroAdmin) {
      newWorkListFilter = this.workListService.createCustomerDocumentWorkList(this.trusteeRef, customer.id);
    } else {
      newWorkListFilter = this.workListService.createCustomerFieldRequestWorkList(loggedUser, this.trusteeRef, customer.id);
    }
    this.workListService.initNewWorkList(newWorkListFilter);
    this.customerSelectVisible = false;
  }
}
