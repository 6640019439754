import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {WsCustomerDocument} from "@fiscalteam/nitro-domain-client";
import {EMPTY, Observable, of, shareReplay, Subscription, switchMap, take} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {IndexerFrontConfigService} from "../../config/indexer-front-config.service";
import {WorkListService} from "../../service/work-list-service";
import {WorkListNavigationStateService} from "../../service/work-list-navigation-state.service";

@Component({
  selector: 'idx-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss'],
})
export class DocumentDetailsComponent implements OnInit, OnDestroy {

  document$: Observable<WsCustomerDocument | undefined> = EMPTY;
  workItemBlob$: Observable<Blob | undefined> = EMPTY;

  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private workListService: WorkListService,
    private workListNavigationStateService: WorkListNavigationStateService,
    private configService: IndexerFrontConfigService,
  ) {

  }

  ngOnInit(): void {
    const workListItem$ = this.workListService.getWorkListItem$().pipe(
      debounceTime(100),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.document$ = workListItem$.pipe(
      map(item => item?.document),
      shareReplay({bufferSize: 1, refCount: true})
    );
    this.workItemBlob$ = workListItem$.pipe(
      switchMap(item => item?.docBytesBlob$ || of(undefined)),
      shareReplay({bufferSize: 1, refCount: true}),
    );

    this.subscription = new Subscription();
    // document displayed is stored in the navigation service state. We use that as source, and subscribe to it
    // to renavigate whenever it changes
    const navigateOnDocCHangeSubscription = workListItem$.pipe(
    ).subscribe({
      next: i => {
        if (i && i.id != null) {
          this.router.navigate(['/', i.id], {});
        }
      }
    });
    this.subscription.add(navigateOnDocCHangeSubscription);

    if (this.configService.isFeatureEnabled("document.worklist.preload")) {
      const preloadSubscription = this.workListNavigationStateService.subscribeToPreloadNextItemAhead(3)
      this.subscription.add(preloadSubscription);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  reloadWorkList() {
    this.workListService.getActiveWorkListFilter$().pipe(
      take(1)
    ).subscribe(filter => this.workListService.initNewWorkList(filter));
  }

}
