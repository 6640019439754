import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {WsThirdParty, WsThirdPartyIdentifier, WsThirdPartyIdentifierType, WsThirdPartyType} from "@fiscalteam/nitro-domain-client";
import {CountryService, IndexingFieldHandlersService, ThirdpartyService} from "@fiscalteam/ngx-nitro-services";
import {ThirdpartyFormModel} from "./thirdparty-form-model";

// ngModel-bound = ThirdpartyFormModel
@Component({
  selector: 'idx-thirdparty-form',
  templateUrl: './thirdparty-form.component.html',
  styleUrls: ['./thirdparty-form.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ThirdpartyFormComponent,
    multi: true,
  }]
})
export class ThirdpartyFormComponent implements OnInit, OnDestroy, ControlValueAccessor {

  value?: ThirdpartyFormModel;
  thirdParty: WsThirdParty | undefined;

  identifiers: WsThirdPartyIdentifier[] = [];
  WsThirdPartyIdentifierType = WsThirdPartyIdentifierType;

  private onTouched: Function | undefined;
  private onChange: Function | undefined;

  private subscription: Subscription | undefined;

  constructor(
    private countryService: CountryService,
    private thirdPartyService: ThirdpartyService,
    private fieldhandlerService: IndexingFieldHandlersService,
  ) {
  }

  ngOnInit(): void {
    this.subscription = new Subscription();
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: ThirdpartyFormModel): void {
    this.loadThirdParty(obj);
  }

  fireChanges() {
    const safeValue: ThirdpartyFormModel = this.value || {} as ThirdpartyFormModel;
    safeValue.thirdparty = this.thirdParty;
    safeValue.identifiers = this.identifiers;

    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onChange) {
      this.onChange(safeValue);
    }
  }

  onVatNumberChange(value: string) {
    this.fireChanges();
  }

  onPersonEntityValueChange(isPersonEntity: boolean) {
    if (isPersonEntity) {
      this.thirdParty = Object.assign({}, this.thirdParty, {
        thirdPartyType: WsThirdPartyType.PersonEntity,
        ignoreIdentificationConstraints: true,
        vatNumber: undefined
      } as Partial<WsThirdParty>);

    } else {
      this.thirdParty = Object.assign({}, this.thirdParty, {
        thirdPartyType: WsThirdPartyType.LegalEntity,
        ignoreIdentificationConstraints: false,
      } as Partial<WsThirdParty>);

    }
    this.fireChanges();
  }

  private async loadThirdParty(formModel: ThirdpartyFormModel): Promise<any> {
    this.value = formModel;
    this.thirdParty = formModel ? formModel.thirdparty : undefined;
    this.identifiers = formModel ? formModel.identifiers : [];
  }

}
