<div class="document-worklist-navigator w-full">

  <div *ngIf="showWorkListName"
       class="worklist-name w-full mb-2 lg">
    {{workListName$ | async}}
  </div>

  <div *ngIf="showActiveDocumentName"
       class="active-doc-name w-full my-2 text-sm">
    <div *ngIf="showActiveDocumentId && (activeDocumentId$ | async) != null">Document #{{activeDocumentId$ | async}}</div>
    <div class="text-xs">{{ activeDocumentLabel$ | async}}</div>
  </div>

  <div *ngIf="showPaginator"
       class="w-full flex align-items-center justify-content-between"
  >

    <button type="button" pButton
            *ngIf="showPaginatorFirstLast"
            class="p-button-text p-button-secondary flex-0"
            icon="pi pi-angle-double-left"
            title="Premier document"
            [disabled]="disabled || (hasPrevDoc$ | async) === false"
            (click)="onNavigateFirstClick()"
    ></button>


    <button type="button" pButton
            *ngIf="showPaginatorPrevNext"
            class="p-button-text p-button-secondary flex-0"
            icon="pi pi-angle-left"
            title="Document précédent"
            [disabled]="disabled || (hasPrevDoc$ | async) === false"
            (click)="onNavigateDeltaClick(-1)"
    ></button>

    <div *ngIf="showPaginatorIndexTotalCount" class="flex-1 mx-2 text-center">
      <ng-container *ngIf="(docIndex$ | async) != null; else noIndex">
        <span>
          {{ ((docIndex$ | async) || 0) + 1 }}
        </span>
        <span> / </span>
        <span>
          {{ totalCount$ | async }}
        </span>
      </ng-container>
      <ng-template #noIndex>
        <span> - </span>
      </ng-template>
    </div>

    <button type="button" pButton
            *ngIf="showPaginatorPrevNext"
            class="p-button-text p-button-secondary flex-0"
            icon="pi pi-angle-right"
            title="Document suivant"
            [disabled]="disabled || (hasNextDoc$ | async) === false"
            (click)="onNavigateDeltaClick(+1)"
    ></button>


    <button type="button" pButton
            *ngIf="showPaginatorFirstLast"
            class="p-button-text p-button-secondary flex-0"
            icon="pi pi-angle-double-right"
            title="Dernier document"
            [disabled]="disabled || (hasNextDoc$ | async) === false"
            (click)="onNavigateLastClick()"
    ></button>

  </div>
</div>
