<div class="worklist-select inline-block">
  <p-button
          (onClick)="workListOverlay.toggle($event)"
          [label]="(activeWorkListName$ | async) || ''"
          icon="pi pi-angle-down"
          iconPos="right"
          size="small"
          outlined="true"
  >
  </p-button>
  <p-overlayPanel #workListOverlay>
    <button type="button" pButton
            class="p-button-text p-button-plain w-full text-left"
            label="Liste de travail"
            [routerLink]="['/list']"
            (click)="workListOverlay.hide()"
    ></button>
    <button type="button" pButton
            class="p-button-text p-button-plain w-full text-left"
            label="Dossiers a indexer"
            [routerLink]="['/customerList']"
            (click)="workListOverlay.hide()"
    ></button>

    <div *ngFor="let item of availableWorkListMenu">
      <button type="button" pButton
              class="p-button-text p-button-plain w-full text-left"
              (click)="onItemClick(item); workListOverlay.hide()"
              [label]="item.label || ''"
      ></button>
    </div>
  </p-overlayPanel>
</div>


<p-dialog #customerSelectDialog
          [(visible)]="customerSelectVisible"
          [modal]="true"
          header="Choisir un dossier"
>
  <nit-customer-autocomplete ngModel
                             (ngModelChange)="onCustomerChosen($event)"
                             [showDropDown]="true"
                             [completeOnFocus]="true"
                             [autofocus]="true"
                             [trusteeRef]="trusteeRef"
                             [appendTo]="'body'"
  ></nit-customer-autocomplete>

</p-dialog>
