import {ErrorHandler, Injectable} from "@angular/core";
import {ErrorService, NitroMessageService} from "@fiscalteam/ngx-nitro-services";


@Injectable()
export class IndexerErrorHandler implements ErrorHandler {

  constructor(
    private nitroMessageService: NitroMessageService,
    private errorService: ErrorService,
  ) {
  }

  handleError(error: any): void {
    console.warn(`Error: ${error}`);
    // Ignore well known errors
    const message = error.message;
    const ignored = message === "Transport destroyed";
    if (!ignored) {
      this.nitroMessageService.showError(`Erreur lors du chargement du pdf`, error);
      this.errorService.handleError(error);
    }
  }

}
