import {BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, shareReplay, switchMap} from "rxjs";
import {DocumentWorkListFilter, FieldRequestWorkListFilter, WorkListFilter} from "./worklist/work-list-filter";
import {WorkListState} from "./worklist/work-list-state";
import {AccountingDataService, CustomerDocumentService, FieldIdentificationRequestService, Pagination, PaginationUtils} from "@fiscalteam/ngx-nitro-services";
import {WsCustomerDocument, WsFieldIdentificationRequestGroupField, WsGroupSummaryWsFieldIdentificationRequestGroupFieldObject, WsRefWsCustomerDocument, WsResultPageWsCustomerDocument, WsResultPageWsGroupSummaryWsFieldIdentificationRequestGroupFieldObject} from "@fiscalteam/nitro-domain-client";
import {WorkListItem} from "./worklist/work-list-item";
import {debounceTime, map, tap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {WsStoredFileUrl} from "@fiscalteam/nitro-domain-client/model/wsStoredFileUrl";


/**
 * A service for maintaining a work list state, fetching next documents as required.
 *
 * To use it, a work list filter must be provided, then for loading following pages, page offset may
 * be provided.
 *
 * A worklist, As items from the worklist might become non elligible between page loads, attention must be paid to the
 * 'totalCount' property of the state as well as properly handle a state with an empty item list.
 */
@Injectable({
  providedIn: "root"
})
export class WorkListStateService {

  private workListFilterSource$ = new BehaviorSubject<WorkListFilter<any, any> | undefined>(undefined);
  private pageOffsetSource$ = new BehaviorSubject<number>(0);
  private workListLoading$ = new BehaviorSubject<boolean>(false);
  private readonly workListState$: Observable<WorkListState<any>>;

  constructor(
    private customerDocumentService: CustomerDocumentService,
    private accountingDataService: AccountingDataService,
    private fieldRequestService: FieldIdentificationRequestService,
  ) {
    this.workListState$ = combineLatest([
      this.workListFilterSource$,
      this.pageOffsetSource$
    ]).pipe(
      debounceTime(0), // Force async, so that emitting in both subject at once does not trigger two requests
      tap(e => this.workListLoading$.next(true)),
      switchMap(([filter, pageOffset]) => this.loadWorkListPage$(filter, pageOffset)),
      tap(e => this.workListLoading$.next(false)),
      shareReplay(({bufferSize: 1, refCount: true}))
    )
  }

  /**
   * Returns an Observable that emits the current loading state of the work list.
   *
   * @return {Observable<boolean>} An Observable that emits a boolean value indicating the loading state.
   */
  isLoading$(): Observable<boolean> {
    return this.workListLoading$.asObservable();
  }

  /**
   * Returns an Observable that emits the current WorkListState.
   *
   * @return {Observable<WorkListState<any>>} An Observable that emits the current WorkListState.
   */
  getWorkListState$(): Observable<WorkListState<any>> {
    return this.workListState$;
  }

  /**
   * Sets the work list filter, and optionally page offset.
   * Upon calling this methods, a new work list state will be emitted.
   *
   * @param {WorkListFilter<any, any>} filter - The filter to be applied to the work list.
   * @param {number} [pageOffset=0] - The offset of the page to be displayed.
   *
   * @return {void}
   */
  setWorkListFilter(filter?: WorkListFilter<any, any>, pageOffset = 0) {
    this.workListFilterSource$.next(filter);
    this.pageOffsetSource$.next(pageOffset);
  }

  /**
   * Sets the page offset for the work list.
   * Upon calling this method, a new work list state will be emitted.
   *
   * @param {number} offset - The offset value to set for the page.
   * @return {void}
   */
  setWorkListPageOffset(offset: number) {
    this.pageOffsetSource$.next(offset);
  }

  private loadWorkListPage$(workListFilter: WorkListFilter<any, any> | undefined,
                            pageOffset: number) {
    if (workListFilter == null) {
      return EMPTY;
    }
    const pagination: Pagination = PaginationUtils.newPagination(
      pageOffset,
      workListFilter.pageSize,
      workListFilter.sorts
    );

    switch (workListFilter.workListType) {
      case "document":
        return this.customerDocumentService.searchCustomerDocuments$(workListFilter.filter, pagination).pipe(
          switchMap(results => this.createDocumentWorkListState$(results, <DocumentWorkListFilter>workListFilter, pageOffset))
        );
      case "fieldRequest":
        pagination.group = WsFieldIdentificationRequestGroupField.Document;
        return this.fieldRequestService.searchFieldIdentificationRequestsGroups$(workListFilter.filter, pagination).pipe(
          switchMap(results => this.createFieldRequestWorkListState$(results, <FieldRequestWorkListFilter>workListFilter, pageOffset))
        );
      default:
        throw new Error(`Unhandled worklist type ${workListFilter.workListType}`);
    }
  }


  private createDocumentWorkListState$(results: WsResultPageWsCustomerDocument, workListFilter: DocumentWorkListFilter, pageOffset: number) {
    const documentList = results.itemList || [];
    const totalCount = results.totalCount || 0;
    const workListItems: WorkListItem[] = documentList.map(doc => this.createDocumentWorkListItem(doc));

    const workListState: WorkListState<DocumentWorkListFilter> = {
      workListFilter: workListFilter,
      pageOffset: pageOffset,
      totalCount: totalCount,
      pageItems: workListItems
    };
    return of(workListState);
  }

  private createFieldRequestWorkListState$(results: WsResultPageWsGroupSummaryWsFieldIdentificationRequestGroupFieldObject,
                                           workListFilter: FieldRequestWorkListFilter, pageOffset: number) {
    const groupSummariesList = results.itemList || [];
    const totalCount = results.totalCount || 0;

    const workListItem$List: Observable<WorkListItem>[] = groupSummariesList.map(
      summary => this.createFieldRequestGroupSummaryWorkListItem$(summary)
    );
    const workListItems$ = workListItem$List.length === 0 ? of([]) : forkJoin(workListItem$List);
    return workListItems$.pipe(
      map(items => {
        const workListState: WorkListState<FieldRequestWorkListFilter> = {
          workListFilter: workListFilter,
          pageOffset: pageOffset,
          totalCount: totalCount,
          pageItems: items
        };
        return workListState;
      })
    );
  }

  private createDocumentWorkListItem(doc: WsCustomerDocument) {
    const accountingData$ = this.accountingDataService.getAccountingData$(doc.accountingDataWsRef!).pipe(
      shareReplay({bufferSize: 1, refCount: false})
    );
    // const fileUri$ = this.customerDocumentService.getSortedFileUrl$(doc.id!).pipe(
    //   shareReplay({bufferSize: 1, refCount: true})
    // );
    const fileBlob$ = this.customerDocumentService.getCustomerDocumentBlob$({id: doc.id!}).pipe(
      shareReplay({bufferSize: 1, refCount: false})
    );
    const workListItem: WorkListItem = {
      id: doc.id!,
      document: doc,
      accountData$: accountingData$,
      docBytesUri$: of(null! as WsStoredFileUrl),
      docBytesBlob$: fileBlob$
    };
    return workListItem;
  }

  private createFieldRequestGroupSummaryWorkListItem$(summary: WsGroupSummaryWsFieldIdentificationRequestGroupFieldObject) {
    const documentRef: WsRefWsCustomerDocument = summary.groupEntity;
    return this.customerDocumentService.getCustomerDocument$(documentRef).pipe(
      map(doc => this.createDocumentWorkListItem(doc))
    );
  }
}
