import {DocumentDetailsComponent} from "./document-details/document-details.component";
import {DocumentFieldsFormComponent} from "./document-details/document-fields-form/document-fields-form.component";
import {resolveThirdParty} from "@fiscalteam/ngx-nitro-services";
import {ThirdpartyFormComponent} from "./document-details/thirdparty-form/thirdparty-form.component";
import {Route} from "@angular/router";
import {DocumentListRoute} from "./document-list/document-list-route";
import {CustomerListComponent} from "./customer-list/customer-list.component";

export const IndexerFrontAppRoutes: Route[] = [

  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '_'
      },
      {
        path: 'list',
        component: DocumentListRoute,
      },
      {
        path: 'customerList',
        component: CustomerListComponent,
      },
      {
        path: ':documentId',
        component: DocumentDetailsComponent,
        runGuardsAndResolvers: "pathParamsChange",
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: DocumentFieldsFormComponent
          },
          {
            path: 'thirdparty/:thirdpartyId',
            resolve: {
              thirdParty: resolveThirdParty('thirdpartyId')
            },
            component: ThirdpartyFormComponent,
          }
        ]
      },
    ],
  }
];
